<template>
  <div class="form--group amenity--section row">
    <label class="col-12 col-lg-3">{{ $t('project.nearby') }}</label>
    <div class="col-12 col-lg-6">
      <nearby-place-field
        v-for="(nearbyPlace, index) in nearbyPlaces"
        :key="index"
        :index="index"
        :nearbyPlace="nearbyPlace"
        ref="nearbyPlaceField"
        :nearbyPlaceLength="nearbyPlaces.length"
        :placeholder="$t('project.nearbyPlc')"
      />
      <div class="col-12 p-0 mt-2">
        <v-btn color="primary" @click="addNearbyPlace" type="button" id="amenityBtn">
          {{ $t('project.btn.addAmenity') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
const NearbyPlaceField = () =>
  import('@/components/project-management/project/form/nearby-places/nearby-place-field');
import { mapState } from 'vuex';

export default {
  name: 'nearby-places-section',
  components: {
    NearbyPlaceField,
  },
  computed: {
    ...mapState({
      nearbyPlaces: (state) => state.project.form.nearbyPlaces,
    }),
  },
  methods: {
    addNearbyPlace() {
      this.$store.commit('project/form/ADD_NEARBY_PLACE');
      // this.$nextTick(function() {
      //   this.$refs.nearbyField[this.amenities.length - 1].$el.querySelector('input').focus();
      // });
    },
  },
};
</script>

<style scoped></style>
